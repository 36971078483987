import React from "react"
import styled from "styled-components";
import Arrow from "../icons/arrow.svg";
import {Link} from "gatsby";


const StyledLink = styled(Link)`
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 150%;
  text-decoration: none;
  padding-bottom: 6.5px;
  letter-spacing: 0.03em;
  color: ${props => props.theme.black};
  border-bottom: 2px solid ${props => props.theme.black};
  transition: ease-in-out, 300ms;
  display: inline-flex;
  align-items: center;
  svg,
  path{
    transition: ease-in-out, 300ms;
  }
  svg {
    padding-left: 10px;
  }
  &:hover,
  &:focus {
    color: ${props => props.theme.red};
    border-color: ${props => props.theme.red};
    svg,
    path{
      fill: ${props => props.theme.red};
    }
  }
`

const StyledExternalLink = styled.a`
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 150%;
  text-decoration: none;
  padding-bottom: 6.5px;
  letter-spacing: 0.03em;
  color: ${props => props.theme.black};
  border-bottom: 2px solid ${props => props.theme.black};
  transition: ease-in-out, 300ms;
  display: inline-flex;
  align-items: center;
  svg,
  path{
    transition: ease-in-out, 300ms;
  }
  svg {
    padding-left: 10px;
  }
  &:hover,
  &:focus {
    color: ${props => props.theme.red};
    border-color: ${props => props.theme.red};
    svg,
    path{
      fill: ${props => props.theme.red};
    }
  }
`

const ArrowLink = (props) => {

  if(props.external) {
    return   (
       <StyledExternalLink href={props.path} target='_blank'>{props.text}  <Arrow/></StyledExternalLink>
    )
  }

  return(
    <StyledLink to={props.path}>{props.text} <Arrow/></StyledLink>
  )
}

export default ArrowLink;
