import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import BlogPost from "../BlogPost";
import styled from "styled-components";
import StyledH2 from "../../styles/StyledH2";


const RelatedPostsWrapper = styled.div`
  padding-bottom: 50px;
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 13px;
    @media(max-width: ${props => props.theme.screenSmMax}) {
      grid-template-columns: 1fr;
      padding-bottom: 30px;
    }
  }
`;

const PageH2 = styled(StyledH2)`
  margin-top: 70px;
  margin-bottom: 50px;
  text-align: center;
`

const RelatedPosts = (props) => {

  // Get all our blog posts
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: {order: DESC, fields: frontmatter___lastupdated}) {
        edges {
          node {
            frontmatter {
              tags
              title
              path
              date
              lastupdated
              logo
              type
            }
            fields {
              slug
            }
          }
        }
        group(field: frontmatter___tags) {
          totalCount
          tag: fieldValue
        }
      }

    }
  `);




  // Filter blog posts to just ones that contain our tags
  let originalPosts = data.allMarkdownRemark.edges;

  // For now we're filtering out everything but our current type

  originalPosts = originalPosts.filter((item) => {
    return item.node.frontmatter.type === props.type;
  });

  // Remove current post from list
  originalPosts = originalPosts.filter((item) => {
    return item.node.fields.slug !== props.currentSlug;
  });

  // Sort posts by date
  originalPosts = originalPosts.sort((a, b) => {
    return Date.parse(b.node.frontmatter.date) - Date.parse(a.node.frontmatter.date);
  });


  // This is an array of tags by count. We're going to order things so that tags that aren't used as often
  // have prominence, since some tags are used on almost all posts.
  let tagOrderArray = data.allMarkdownRemark.group;

  // We only want to consider tags present on the current post.
  if(props.tags) {
    tagOrderArray = tagOrderArray.filter((item) => {
        return props.tags.indexOf(item.tag) !== -1;
      }
    )
  }


  tagOrderArray.sort((p, p2) => {
    return p.totalCount - p2.totalCount;
  });

  // Now we sort our original posts according to these rankings
  // First add rankings to each one
  originalPosts = originalPosts.map(post => {

    for (let i = 0; i < tagOrderArray.length; i++) {
      if (post.node.frontmatter.tags.indexOf(tagOrderArray[i].tag) !== -1) {
        post.ranking = i;
        break;
      }
    }

    return post;

  });


  originalPosts = originalPosts.sort((p, p2) => {
    return p.ranking - p2.ranking;
  });


  // Filter to posts that share tags with this one
  let posts = originalPosts;
  if(props.tags) {
    posts = originalPosts.filter((item) => {
      return item.node.frontmatter.tags.some(r => props.tags.includes(r));
    });
  }



  // If we have fewer than three, add most recent two posts
  if (posts.length < 3) {
    let neededNumber = 3 - posts.length;
    let neededPosts = originalPosts.slice(0, neededNumber);
    posts = posts.concat(neededPosts);


  } else {
    // Otherwise, trim to three
    posts = posts.slice(0, 3);
  }


  let renderedPosts = posts.map((post) => {

    return (
      <BlogPost
        path={post.node.frontmatter.path}
        title={post.node.frontmatter.title}
        date={post.node.frontmatter.date}
        imageName={post.node.frontmatter.logo}
      />
    )
  })


  return (
    <RelatedPostsWrapper>
      <PageH2>Related Posts</PageH2>
      <div>
        {renderedPosts}
      </div>
    </RelatedPostsWrapper>
  )
}

export default RelatedPosts;
