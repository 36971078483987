import React from "react"
import styled from "styled-components";
import ContainerSmall from "../styles/ContainerSmall";


const CaseStudyQuoteWrapper = styled.div`
 background-color: ${props => props.theme.blue};
 padding-top: 50px;
 padding-bottom: 50px;
 margin-top: 40px;
  @media(max-width: ${props => props.theme.screenSmMax}) {
    padding-right: 20px;
    padding-left: 20px;
  }

 > div {
  display: flex;
  justify-content: space-between;
  @media(max-width: ${props => props.theme.screenSmMax}) {
    flex-direction: column;
  }
  > div:first-child {

  margin-right: 80px;
    margin-top: 20px;
       @media(max-width: ${props => props.theme.screenSmMax}) {
    margin-right: 0;
  }
     span {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 130%;

        /* identical to box height, or 21px */
        letter-spacing: 0.03em;

        /* Primary/White */
        color: #FFFFFF;
        margin-bottom: 20px;
     }
      p {
        font-family: Nunito Sans, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 1.375rem;
        @media(max-width: ${props => props.theme.screenSmMax}) {
          font-size: 1.1rem;
        }
        line-height: 130%;

        /* or 29px */
        letter-spacing: 0.03em;

        /* Primary/White */
        color: #FFFFFF;
       }
    }
  > div:nth-child(2) {

    max-width: 760px;
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 2.125rem;
    line-height: 150%;

    /* or 51px */
    letter-spacing: 0.03em;

    /* Primary/White */
    color: #FFFFFF;
    @media(max-width: ${props => props.theme.screenSmMax}) {
      font-size: 1.5rem;
    }
  }
  }
`

const CaseStudyQuote = (props) => {
  return (
    <CaseStudyQuoteWrapper>
      <ContainerSmall>
        <div>
          <span>
            {props.source}
          </span>
          <p>{props.stafftitle}</p>
        </div>
        <div>
          {props.quote}
        </div>

      </ContainerSmall>


    </CaseStudyQuoteWrapper>
  )
}

export default CaseStudyQuote;
