import React from "react"
import {graphql} from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import CaseStudyLogo from "../components/CaseStudyLogo";
import styled from "styled-components";
import ContainerSmall from "../styles/ContainerSmall";
import ArrowLink from "../components/ArrowLink";
import PostText from "../styles/PostText";
import RelatedPosts from "../components/blog/RelatedPosts";
import StyledH1 from "../styles/StyledH1";
import CaseStudyQuote from "../components/CaseStudyQuote";


const CaseStudyHeader = styled(ContainerSmall)`
  @media(max-width: ${props => props.theme.screenSmMax}) {
   padding-top: 30px;
  }

  .gatsby-image-wrapper {
    margin-bottom: 50px;
   
  }

  p {
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 150%;

    /* or 24px */
    letter-spacing: 0.03em;

    color: #000000;
  }
`;

const PageH1 = styled(StyledH1)`
    margin-top: 0;
    margin-bottom: 20px;
`



const PageHighlight = styled.div`
  font-size: 1.375rem;
line-height: 130%;

letter-spacing: 0.03em;
`

const PostTextWrapper = styled.div`
  @media(max-width: ${props => props.theme.screenSmMax}) {
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 40px;
  }
  h2,
  h3,
  p {
    word-wrap: anywhere;
  }
`

const CaseStudyHeaderLower = styled.div`
  display: grid;
  grid-template-columns: 7fr 3fr;
  gap: 80px;
  @media(max-width: ${props => props.theme.screenSmMax}) {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  a {
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
  }
`




const casestudy = (props) => {

  const post = props.data.markdownRemark
  const siteTitle = props.data.site.siteMetadata.title


  let arrowLink = null;

  if(post.frontmatter.url) {
    arrowLink = <div>
      <ArrowLink path={post.frontmatter.url} text={'View the Catalog'} external={true}/>
    </div>;
  }

  return (


    <Layout location={props.location} title={siteTitle} grayFooter={true}>
      <CaseStudyHeader>

        <CaseStudyLogo
          imageName={post.frontmatter.logo}
        />
        <CaseStudyHeaderLower>
          <div>
            <PageH1>{post.frontmatter.title}</PageH1>
            <PageHighlight>{post.frontmatter.lead}</PageHighlight>
          </div>
          {arrowLink}
        </CaseStudyHeaderLower>

      </CaseStudyHeader>
      <ContainerSmall>
        <PostTextWrapper>
          <PostText dangerouslySetInnerHTML={{__html: post.html}}/>
        </PostTextWrapper>
      </ContainerSmall>
      <CaseStudyQuote
        source={post.frontmatter.source}
        stafftitle={post.frontmatter.stafftitle}
        quote={post.frontmatter.quote}
      />

      <ContainerSmall>

        <RelatedPosts
          type={'casestudy'}
          currentSlug={props.pageContext.slug}
          tags={post.frontmatter.tags}
        />

      </ContainerSmall>

    </Layout>
  )

}

export default casestudy;

export const Head = (props) => {
  let seotitle = props.data.markdownRemark.frontmatter.title;
  if (props.data.markdownRemark.frontmatter.seotitle) {
    seotitle = props.data.markdownRemark.frontmatter.seotitle;
  }


  return (
    <Seo
      title={seotitle}
      description={props.data.markdownRemark.frontmatter.description || props.data.markdownRemark.excerpt}
      canonical={props.location.pathname}
    />
  )
}



export const pageQuery = graphql`
  query CaseStudyBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date
        path
        screenshot
        school
        quote
        url
        stafftitle
        source
        lead
        seotitle
        logo
        tags
      }
    }
  }
`
