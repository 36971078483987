import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";


const CaseStudyLogo = (props) => {

  // Get all of our images, and then filter, since we can't use props in the query
  const data = useStaticQuery(graphql`{
  allFile(filter: {relativeDirectory: {eq: "logos"}}) {
    nodes {
      childImageSharp {
        gatsbyImageData(height: 75, placeholder: NONE, layout: FIXED)
      }
    name
    }
  }
}
`);


  // Filter to just our current image, passed down in props
  let src = data.allFile.nodes.filter((item) => {
    return props.imageName === item.name;
  });


  let image = null;

  let imgSrc = src.filter((item) => {
    return item.node !== null && item.childImageSharp.gatsbyImageData !== null;
  });


  if(imgSrc !== null && imgSrc.length > 0) {
    image = <GatsbyImage image={getImage(imgSrc[0])} alt={props.imageName}/>;
  }

  return (
    image
  )
}

export default CaseStudyLogo;
