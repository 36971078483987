import React from "react"
import styled from "styled-components";
import ResponsiveEmbed from "react-responsive-embed";


const VideoWrapper = styled.div`

`;

const Video = (props) => {
  let vimeoUrl = `https://player.vimeo.com/video/${props.vimeoid}?texttrack=en`
  let iframe = <ResponsiveEmbed title={'Clean Catalog Video'} src={vimeoUrl} allowfullscreen />
  return(
    <VideoWrapper>
      {iframe}
    </VideoWrapper>
  )
}

export default Video;
