import styled from "styled-components";

const StyledH2 = styled.h2`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 2.875rem;
    line-height: 150%;
    letter-spacing: 0.03em;
    color: #000000;

    @media(max-width: ${props => props.theme.screenSmMax}) {
     font-size: 2.3rem;
    }
`;

export default StyledH2;
