import styled from "styled-components";

const PostText = styled.section`
  max-width: 760px;
  margin-left: auto;
  margin-top: 100px;
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: #141414;
  @media(max-width: ${props => props.theme.screenSmMax}) {
    margin-top: 0;
  }


    h2 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 110%;
    letter-spacing: 0.03em;
    color: #141414;
    margin-top: 40px;
    margin-bottom: 10px;
  }
  a {
    color: ${props => props.theme.red};
  }
  strong {
    font-weight: bold;
    }
`;

export default PostText;
