import React from "react"
import styled from "styled-components";
import {Link} from "gatsby";
import Arrow from "../icons/arrow.svg";
import CaseStudyLogo from "./CaseStudyLogo";
import Video from "./Video";


const PostWrapper = styled.article`
  background: #fff;
  box-shadow: ${props => props.theme.boxShadow};
  position: relative;
  z-index: 10; // So that it goes over underlay from PageHeader.js
  svg, path {
    transition: ${props => props.theme.transition};
  }

  p {
    color: #000;
    transition: ${props => props.theme.transition};
  }

  a {
    transition: ${props => props.theme.transition};
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;

    border-bottom: solid 2px transparent;
    height: 100%;
    box-sizing: border-box;
    &:hover,
    &:focus {
    border-bottom: solid 2px ${props => props.theme.red};
      h2, p {
        color: ${props => props.theme.red};
      }
      div {
        color: ${props => props.theme.red};
      }
      path {
        fill: ${props => props.theme.red};
      }
    }
  }
  h2 {
    transition: ${props => props.theme.transition};
    margin: 0;
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1.75rem;
    line-height: 150%;
    color: ${props => props.theme.black};
  }
 `

const DateWrapper = styled.div`
  transition: ${props => props.theme.transition};
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.black};
`


const BlogPost = (props) => {

  let text = null;
  if (props.text) {
    text = <p>{props.text}</p>;
  }

  // Only show date/read more if there's no logo
  let date = null;
  if(!props.imageName) {
    date = props.date;
  }


  let video = null;
  if(props.vimeoid) {
    video = <Video vimeoid={props.vimeoid} />;
  }


  return (
    <PostWrapper>
      <Link to={props.path}>
        <h2>{props.title}</h2>
        {text}
        {video}
        <DateWrapper>
          {date}
          <CaseStudyLogo
            imageName={props.imageName}
          />
          <Arrow/>
        </DateWrapper>
      </Link>
    </PostWrapper>
  )
}

export default BlogPost;
